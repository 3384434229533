import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Heading from '../../Components/Heading/heading.tsx';
import LocationOnIcon from '@mui/icons-material/LocationOn';

interface LocationModalProps {
  open: boolean;
  onClose: () => void;
  startLocation: string;
  endLocation: string;
}

const LocationModal: React.FC<LocationModalProps> = ({ open, onClose, startLocation, endLocation }) => {
  const startLocationUrl = `https://www.google.com/maps?q=${encodeURIComponent(startLocation)}`;
  const endLocationUrl = `https://www.google.com/maps?q=${encodeURIComponent(endLocation)}`;
  return (
    <Dialog open={open} onClose={onClose}>
       <Heading title="Location Details"  closeIcon="true" onClose={onClose} />
      <DialogContent>
        <div>
          <strong>Start Location:</strong> {startLocation} <a href={startLocationUrl} target="_blank" rel="noopener noreferrer">
          <LocationOnIcon style={{color: 'green'}} />
          </a>
        </div>
        <div>
          <strong>End Location:</strong> {endLocation}
          <a href={endLocationUrl} target="_blank" rel="noopener noreferrer">
            <LocationOnIcon  style={{color: 'red'}} />
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LocationModal;
