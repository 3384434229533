import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import {uniqBy, sortBy} from "lodash";
import { BiTrip } from "react-icons/bi"
const BASE_URL = `${window.location.origin}`;

export const FinalSidebarData = [
  {
    title: 'Dashboard',
    path: '' + BASE_URL + '/dashboard',
    icon: <AiIcons.AiFillHome style={{ color: 'black' }} />,   
     cName: 'nav-text disabled'
  },
  {
    title: 'Orders',
    path: '' + BASE_URL + '/orders',
    icon: <FaIcons.FaFileUpload  style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Trips',
    path: '' + BASE_URL + '/trips',
    icon: <BiTrip style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Vehicles',
    path: '' + BASE_URL + '/vehicles',
    icon: <FaIcons.FaCarAlt style={{ color: 'black' }} />,   
    cName: 'nav-text'
  },
  {
    title: 'Routes',
    path: '' + BASE_URL + '/maps',
    icon: <FaIcons.FaMapMarkerAlt style={{ color: 'black' }} />,   
    cName: 'nav-text disabled'
  },
  {
    title: 'Offers',
    path: '' + BASE_URL + '/offers',
    icon: <FaIcons.FaGift style={{ color: 'black' }} />,   
    cName: 'nav-text disabled'
  },
  
  {
    title: 'Reports',
    path: '' + BASE_URL + '/reports',
    icon:  <IoIcons.IoIosPaper style={{ color: 'black' }} />,   
    cName: 'nav-text disabled'
  },
  {
    title: 'Documents',
    path: '' + BASE_URL + '/documents',
    icon:  <FaIcons.FaAddressBook style={{ color: 'black' }} />,   
    cName: 'nav-text disabled'
  },
  {
    title: 'Whatsapp',
    path: '' + BASE_URL + '/whatsapp',
    icon: <FaIcons.FaWhatsapp style={{ color: 'black' }} />,   
    cName: 'nav-text disabled'
  },
  {
    title: 'Users',
    path: '' + BASE_URL + '/users',
    icon: <FaIcons.FaUser style={{ color: 'black' }} />,   
    cName: 'nav-text'
  }
];
const FinallySidebarData = uniqBy(FinalSidebarData, 'title');
export default FinallySidebarData;