import React, { useEffect, useState } from 'react';
import './sidenav.scss';
import { IconContext } from 'react-icons';
import * as AiIcons from 'react-icons/ai';

type props = {
  selected?: string;
};

const NavBar: React.FC<props> = ({ selected }) => {
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
useEffect(() => {
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  window.addEventListener('resize', handleResize);
  return () => {
  window.removeEventListener('resize', handleResize);
    };
  }, []);
const SidebarData = require('./sidebarData').default;
const expandSidebar = () => {
    var x = document.getElementById('myLinks');
    if (x) {
      if (x.style.display === 'block') {
        x.style.display = 'none';
      } else {
        x.style.display = 'block';
      }
    }
  };
return (
    <>
      {windowWidth > 600 && (
        <IconContext.Provider value={{ color: '#fff' }}>
          <div className='sidebar'>
            {SidebarData.map((item, index) => {
              return (
                  <a key={index} href={item.path} className={item.title == selected ? item.cName+" highlight" : item.cName}>
                    {item.icon}&nbsp;
                    <span>{item.title}</span>
                  </a> 
              );
            })}
          </div>
        </IconContext.Provider>
      )}
      {windowWidth <= 600 && (
        <div className='topnav'>
          <div id='myLinks'>
            {SidebarData.map((item, index) => {
              console.log('Selected:', selected);
              console.log('Item Title:', item.title);
              <a key={index} href={item.path} className={item.cName}>
                {item.icon}&nbsp;
                <span>{item.title}</span>
              </a>
            })}
          </div>
          <AiIcons.AiOutlineBars onClick={() => expandSidebar()} />
        </div>
      )}
    </>
  );
};
export default NavBar;
