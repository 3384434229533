import axios, { AxiosResponse, AxiosError } from "axios";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;

export const getTrips = (
  per_page_records: number,
  page_number: number,
  from_date: string,
  to_date: string,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
  vehicle_id?: number,
  client_name?:any
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${global.api_url}/api/get_trips`, {
      params: {
        per_page_records,
        page_number,
        from_date,
        to_date,
        vehicle_id,
        client_name
      },
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      }
      else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else{
        toast.error("Server Error");
      }
    });
};

export const getVehicle = (
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`
  };
  axios
    .get(`${global.api_url}/api/vehicles`, {
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      }
      else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else{
        toast.error("Server Error");
      }
    });
};

export const editRemarks = (
  trip_id: number,
  key: 'remarks' | 'odometer_start' | 'odometer_end',
  value: string,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void
) => {
  const payload = { [key]: value };
  axios.put(`${global.api_url}/api/edit_trips/${trip_id}`, payload, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  })
    .then((response: AxiosResponse) => {
      _successCallBack(response.data);
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
      } else {
        toast.error("Server Error");
      }
    });
};

    export const getClients = (
      _successCallBack: (response: any) => void,
      _errorCallBack: (errMessage: any) => void,
    ) => {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      axios
        .get(`${global.api_url}/api/clients`, {
          headers: headers,
        })
        .then((response: AxiosResponse) => {
          if (response.status === 200) {
            _successCallBack(response.data);
          }
        })
        .catch((error: AxiosError) => {
          console.log('Error caught:', error);
          if (error.response?.status === 400) {
            _errorCallBack(error.response.data);
          }
          else if  (error.response?.status === 401){
            localStorage.clear();
          }
          else{
            toast.error("Server Error");
          }
        });
    };