import React from 'react';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import './heading.scss';
import Typography from '@mui/material/Typography';

interface HeadingProps {
  title: string;
  closeIcon: string;
  onClose ?: () => void;
}
const Heading: React.FC<HeadingProps> = ({ title ,closeIcon, onClose }) => {
  return (
    <div>
        {closeIcon === 'false' ? (
            <div className='heading title-align'>{title}</div>
         ) : (
            <div className='heading title-align' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 0px' }}>
               <div  style={{ flex: 1 }}>
                   <Typography fontWeight="bold" variant='h6' component="div">
                         {title}
                   </Typography>
                </div>
                <div>
                    <IconButton onClick={onClose} aria-label="close">
                    <CancelIcon />
                    </IconButton>
                </div>
            </div>)}
     </div>
);
};

export default Heading;
