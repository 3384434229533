import React,{useState,useRef, useEffect} from 'react';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
interface DashboardProps {   
}
const Dashboard: React.FC<DashboardProps> = (props) => {
   return (
    <>   
      <Header/>
      <NavBar selected='Dashboard' />
    </>
    );
};
export default Dashboard;