import React from 'react';

const Footer = () => {

  return (
    <>
      <div className="footer text-center" >
         &nbsp; {new Date().getFullYear()} &copy; <a href="https://mdbootstrap.com/"> Powered by TRACKYO</a>
      </div>
    </>
  );
}

export default Footer;
