import React, { useState,ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './login_style.css';
import appLogo from '../../assets/app_logo.png';
import { getLoginUser, getOTP } from './action.ts';

const LoginPage: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState(['', '', '', '']);
  const [pin, setPin] = useState(['', '', '', '']);
  const isNumericInput = (value: string) => /^[0-9]*$/.test(value);
  const [mobileError, setMobileError] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string>("");
  const createInputRefArray = (length: number) => {
    return Array.from({ length }, () => React.createRef<HTMLInputElement>());
  };
  const otpInputRefs = createInputRefArray(otp.length);
  const pinInputRefs = createInputRefArray(pin.length);
  const navigate = useNavigate();
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isValidPhoneNumber = /^[0-9]\d*$/.test(value);

    if (isValidPhoneNumber || value === '') {
      setPhoneNumber(value);
// Check if the phone number starts with 0, 1, 2, 3, 4, or 5
      if (/^[0-5]/.test(value)) {
        setMobileError('Phone number must start with 6, 7, 8, or 9');
      } else {
        setMobileError('');
      }
      
      if (value.length > 10) {
        setMobileError('Phone number must be 10 digits');
      }
    } else {
      setMobileError('Invalid phone number');
    }
  };

  const handleOtpChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value !== '' && index < otp.length - 1) {
      otpInputRefs[index + 1].current?.focus();
    }
  if (index === 3 && value !== '') {
      const loginInput = {
        phone: phoneNumber,
        password: pin.join(''),
        language: 'EN',
        country_code: '91',
        otp: newOtp.join(''),
      };
    const successCallBack = (response: any) => {
      if (response.status === 200) {
        localStorage.setItem("userdetails", JSON.stringify(response));
        const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
        toast.success('Login successful');
        navigate('/dashboard');
        }
      };
    const errorCallBack = (errMessage: any) => {
      if (errMessage.response?.status === 400) {
        toast.error(errMessage.message);
      } else {
        toast.error(errMessage.message);
        }
      };
      getLoginUser(loginInput, successCallBack, errorCallBack);
    }
  };
  const handlePinChange = (index: number, value: string) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);
    if (value !== '' && index < pin.length - 1) {
      pinInputRefs[index + 1].current?.focus();
    }
  };
  const handleLogin = () => {
    if (!phoneNumber) {
      setMobileError('Mobile number is required');
      toast.error('Mobile number is required');
      return;
    }
else if (phoneNumber.length <10)
      {
        setMobileError('Mobile number must be 10 digits')
        return;
      }
    if ((pin.some((digit) => digit === '') || pin.length !== 4) && (otp.some((digit) => digit === '') || otp.length !== 4)) {
      setPasswordError('Enter valid PIN or OTP');
      toast.error('Enter valid PIN or OTP');
      return;
    }
      const loginInput = {
      phone: phoneNumber,
      password: pin.join(''),
      language: 'EN',
      country_code: '91',
      otp: otp.join(''),
    };
      const successCallBack = (response: any) => {
      if(response.status === 200){
        localStorage.setItem("userdetails", JSON.stringify(response));
          const userDetailsFromStorage = JSON.parse(localStorage.getItem("userdetails") || "null");
                toast.success('Login successful');
      navigate('/dashboard');  
    };
    }
        const errorCallBack = (errMessage: any) => {
      if (errMessage.response?.status === 400) {
        toast.error( errMessage.message);
      } else {
        toast.error( errMessage.message);
      }
    };
    if (passwordError === '' && mobileError === '') {
      getLoginUser(loginInput, successCallBack, errorCallBack);
    }
  };
  const handleGetOTPClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
 if (!phoneNumber) {
      setMobileError('Mobile number is required');
      toast.error('Mobile number is required');
      return;
    }
    else {
      console.log("hello");
      const OTPInput ={
        phone_number: phoneNumber,
        country_code: '+91',
        from_register: 0
      }
      const successCallBack = (response: any) => {
        if(response.status === 200){
        toast.success(response.message); 
      };
      } 
      const errorCallBack = (errMessage: any) => {
        if (errMessage.response?.status === 400) {
          toast.error( errMessage.message);
        } else {
          toast.error( errMessage.message);
        }
      };
      getOTP(OTPInput, successCallBack, errorCallBack);
    }
  };
  return (
    <div className='body'>
      <form className="login-form" style={{justifyContent:"center"}} >
        <div className="logo-container">
          <img src={appLogo} alt="App Logo" className="app-logo" height={'200px'} width={'auto'} />
        </div>
        <div className="input-container"style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div className="country-code-input">
            <input
              type="text"
              value="+91"
              readOnly
            />
          </div>
          <label className="otp-label">
            <input
              type="tel"
              value={phoneNumber}
              placeholder="Mobile Number"
              onChange={handlePhoneNumberChange}
              style={{ height: '2.6em', width: '16.8em' }}
            />
          </label>
        </div>
<div>
          {mobileError && <p className="error-message">{mobileError}</p>}
          </div>
        <div className="input-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <p className="pin-heading" style={{marginRight:'20px'}}>Enter Your PIN</p>
          <div className="pin-input-fields" style={{ display: 'flex', flexDirection: 'row' }}>
            {pin.map((digit, index) => (
              <div key={index}>
                <input
                  ref={pinInputRefs[index]}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (isNumericInput(inputValue)) {
                      handlePinChange(index, inputValue);
                    }
                  }}
                  className="input-digit"
                  inputMode="numeric"
                  pattern="[0-9]*"
                                  />
              </div>
            ))}
          </div>
        </div>
        <button type="button" className="login-button" onClick={handleLogin}>
          Login
        </button>
        <div className="partition">
          <div className="outer-circle">
            <p className="or-text">OR</p>
          </div>
          <div className="partition-line"></div>
        </div>
        <div className="input-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <button className='get-otp-button' onClick={handleGetOTPClick}>  <p className="otp-heading" >Get Your OTP</p> </button>
          <div className="otp-input-fields" style={{ display: 'flex', flexDirection: 'row' }}>
            {otp.map((digit, index) => (
              <div key={index}>
                <input
                  ref={otpInputRefs[index]}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (isNumericInput(inputValue)) {
                      handleOtpChange(index, inputValue);
                    }
                  }}
                  className="input-digit"
                  inputMode="numeric"
                  pattern="[0-9]*"
                                  />
              </div>
            ))}
          </div>
        </div>
        <div>
          <label style={{ fontStyle: 'italic', marginTop:'10px' }}>
            Privacy Policy and Terms of Service apply.
          </label>
        </div>
      </form>
    </div>
  );
};
export default LoginPage;